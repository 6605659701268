import { MenuPerms } from '../../enums/menu-perms'
import { PermKeys } from '../../enums/perm-keys'

export const PermissionUtilDigitalSigning = {
    viewOnly,
    notAllowed
}

function viewOnly() {
    const perms = window.UserPerms;

    if (perms && perms[MenuPerms.DigitalSignatureOption]) {
        const checkPerm = perms[MenuPerms.DigitalSignatureOption];

        return checkPerm[PermKeys.viewOnly];
    }

    return false;
}

function notAllowed() {
    const perms = window.UserPerms;

    if (perms && perms[MenuPerms.DigitalSignatureOption]) {
        const checkPerm = perms[MenuPerms.DigitalSignatureOption];

        return checkPerm[PermKeys.notAllowed];
    }

    return true;
}
