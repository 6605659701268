import { MenuPerms } from '../../enums/menu-perms'
import { PermKeys } from '../../enums/perm-keys'

export const PermissionUtilProjectTicket = {
    allowEdit,
    allowViewOwned,
    allowViewAll
}

/**
 * @param {string} menuPerms Menu name permission string
 */
function allowEdit() {
    const perms = window.UserPerms

    if (perms && perms[MenuPerms.ProjectManagementWithTicketFunction]) {
        const checkPerm = perms[MenuPerms.ProjectManagementWithTicketFunction]
        return checkPerm[PermKeys.canAdministrate]
    }

    return false
}

function allowViewAll() {
    const perms = window.UserPerms

    if (perms && perms[MenuPerms.ProjectManagementWithTicketFunction]) {
        const checkPerm = perms[MenuPerms.ProjectManagementWithTicketFunction]
        return checkPerm[PermKeys.canSeeAllProjects] || allowEdit()
    }

    return false
}

function allowViewOwned() {
    const perms = window.UserPerms

    if (perms && perms[MenuPerms.ProjectManagementWithTicketFunction]) {
        const checkPerm = perms[MenuPerms.ProjectManagementWithTicketFunction]
        return checkPerm[PermKeys.canSeeOnlyProjectsOwned] || allowEdit()
    }

    return false
}