import axios from 'axios';
import { UrlHelper } from "@/helpers/url.helper"
import { clientService } from "@/services/client.service"
import { SettingKeys } from "../enums/setting-keys"
import { CacheKeys } from "../enums/cache-keys";
import { InvoiceAccountingType } from "../enums/invoice-accounting-type";
import { CacheUtils } from "../helpers/cache.utils";

export const settingService = {
    list,
    save,
    getTemplate,
    getSmtp,

    getSmsPackages,
    getPogo,
    getTripletex,
    getSiteSetting,
    getSfCenterSetting,
    getHiddenSetting,
    getEmailJobSetting,
    getGanttChartColorSetting,
    getSignicatSetting,
    getEmailTicketSetting,
    testPop3,

    getCustomerImportSetting,
    clearCache,

    getUserSettings,
    saveUserSettings
}

async function list(prefix) {
    const clientId = clientService.getClientId();
    const query = prefix != null && prefix != '' ? `&prefix=${prefix}` : ''

    const url = UrlHelper.resolveApiUrl(`client/settings?id=${clientId}${query}`);
    const result = await axios.get(url);
    return result.data;
}

async function save(arrObj) {
    const clientId = clientService.getClientId();
    const url = UrlHelper.resolveApiUrl(`client/settings`);

    try {
        const result = await axios.post(url, { id: clientId, items: arrObj });
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err };
    }
}

async function getTemplate(tmplName) {
    const clientId = clientService.getClientId();
    const url = UrlHelper.resolveApiUrl(`client/settings?id=${clientId}&prefix=${tmplName}`);
    const result = await axios.get(url);

    const retObj = {
        Subject: "",
        Body: ""
    };

    if (result.data.status == 0 && result.data.data.length > 0) {
        const item = result.data.data[0]

        if (item.value)
            Object.assign(retObj, JSON.parse(item.value));
    }

    return retObj;
}

async function getSmtp() {
    const clientId = clientService.getClientId();
    const url = UrlHelper.resolveApiUrl(`client/settings?id=${clientId}&prefix=${SettingKeys.SMTP}`);
    const result = await axios.get(url);

    const retObj = {
        SmtpServer: '',
        SmtpPort: 80,
        SmtpUsername: '',
        SmtpPassword: '',
        SmtpEnableSSL: false,
        SmtpAuthentication: false,
        DefaultSenderName: 'No reply',
        DefaultSenderEmail: 'no-reply@servicefirst.no',
        ReplyTo: 'info@servicefirst.no',
        EnableQueueEmail: false
    };

    if (result.data.status == 0 && result.data.data.length > 0) {
        const item = result.data.data[0]

        if (item.value)
            Object.assign(retObj, JSON.parse(item.value));
    }

    return retObj;
}

async function getSmsPackages() {
    const url = UrlHelper.resolveApiUrl(`setting?prefix=${SettingKeys.SMS}`);
    const result = await axios.get(url);

    const retObj = {};

    if (result.data.status == 0 && result.data.data.length > 0) {
        const item = result.data.data[0]

        if (item.value)
            Object.assign(retObj, JSON.parse(item.value));
    }

    return retObj.SMSPackages;
}

async function getPogo() {
    const clientId = clientService.getClientId();
    const url = UrlHelper.resolveApiUrl(`client/settings?id=${clientId}&prefix=${SettingKeys.POGO}`);
    const result = await axios.get(url);

    const retObj = {
        ProductionMode: false,
        AppKey: '4c060cfd-a8d7-4298-bff6-dabc50ee3872',
        ClientKey: 'ac4c4d3d-4261-4f8f-9fec-b7bd8affbec8',
        DefaultUnitId: 5
    };

    if (result.data.status == 0 && result.data.data.length > 0) {
        const item = result.data.data[0];

        if (item.value)
            Object.assign(retObj, JSON.parse(item.value));
    }

    return retObj;
}

async function getTripletex() {
    const clientId = clientService.getClientId();
    const url = UrlHelper.resolveApiUrl(`client/settings?id=${clientId}&prefix=${SettingKeys.TRIPLETEX}`);
    const result = await axios.get(url);

    const retObj = {
        ApiUrl: "https://api.tripletex.io/v2",
        ApiConsumerToken: "test-f118ae40-a319-4115-91c9-9f1c6d22905b",
        ApiEmployeeToken: "test-dc30e057-3a21-445d-8aa6-b0c3067f5fa8",
        SkipEmail: true,

        InvoiceEnable: false,
        InvoiceSendEmail2User: false,
        InvoicePaymentId: 0,

        ImportProductEnable: false,
        ImportProductIntervalHour: 1,
        ImportCustomerEnable: false,
        ImportCustomerIntervalHour: 1
    };

    if (result.data.status == 0 && result.data.data.length > 0) {
        const item = result.data.data[0];

        if (item.value)
            Object.assign(retObj, JSON.parse(item.value));
    }

    return retObj;
}

async function getSiteSetting() {
    const strData = localStorage.getItem(CacheKeys.SiteSettings);
    if (CacheUtils.allowCacheData() && strData)
        return JSON.parse(strData);

    const clientId = clientService.getClientId();
    const url = UrlHelper.resolveApiUrl(`client/settings?id=${clientId}&prefix=${SettingKeys.GENERAL}`);
    const result = await axios.get(url);

    const retObj = {
        SiteName: 'Cateno Service First',
        SiteUrl: 'https://cateno.sf.no',
        CustomNrStartFrom: 1000,
        TwoStepAuthen: false,
        ForceUserLoginUsing2FA: false,
        UserSessionLockTimemout: 30, // in minute
        SettingConfirmPwdTimeout: 20, // in minute,       
        GoogleMapApiKey: 'AIzaSyBrRAFv48_xmfFMQ4tEApQsY04cwPFpadU',

        InvoiceAccountingType: InvoiceAccountingType.Pogo
    };

    if (result.data.status == 0 && result.data.data.length > 0) {
        const item = result.data.data[0]

        if (item.value) {
            Object.assign(retObj, JSON.parse(item.value));

            if (CacheUtils.allowCacheData())
                localStorage.setItem(CacheKeys.SiteSettings, JSON.stringify(retObj));
        }
    }

    return retObj;
}

async function getSfCenterSetting() {
    const url = UrlHelper.resolveApiUrl(`setting?prefix=${SettingKeys.SF_CENTER_GENERAL}`);
    const result = await axios.get(url);

    const retObj = {
        SiteName: 'SF Empty',
        SiteUrl: 'https://sf.no',
        GoogleMapApiKey: 'AIzaSyBrRAFv48_xmfFMQ4tEApQsY04cwPFpadU'
    };

    if (result.data.status == 0 && result.data.data.length > 0) {
        const item = result.data.data[0]

        if (item.value)
            Object.assign(retObj, JSON.parse(item.value));
    }

    return retObj;
}

async function getHiddenSetting() {
    const strData = localStorage.getItem(CacheKeys.HiddenSetting);
    if (CacheUtils.allowCacheData() && strData)
        return JSON.parse(strData);

    const clientId = clientService.getClientId();
    const url = UrlHelper.resolveApiUrl(`client/settings?id=${clientId}&prefix=${SettingKeys.HIDDEN}`);
    const result = await axios.get(url);

    const retObj = {
        BlockerPriorityId: 10170, // tab alert in signalR notify
        ResolveStatusId: 10249, // tab Resolve in customer detail
        OpenStatusId: 10247, // Statistic in PT ticket list
        CloseStatusId: 10251, // Statistic in PT ticket list
        TaskTypeId: 10129, // Use when create ticket from gantt chart OR create project task from project ticket
        BrowserCacheImage: false,
        BrowserCacheData: false,

        DefaultTicketStatusIdPT: 0,
        DefaultTicketStatusIdET: 0,

        DefaultTicketTypeIdPT: 0,
        DefaultTicketTypeIdET: 0,

        DefaultTicketPriorityIdPT: 0,
        DefaultTicketPriorityIdET: 0,

        DefaultTicketResolutionIdPT: 0,
        DefaultTicketResolutionIdET: 0,

        DefaultCreatedById: 0
    };

    if (result.data.status == 0 && result.data.data.length > 0) {
        const item = result.data.data[0];

        if (item.value) {
            Object.assign(retObj, JSON.parse(item.value));

            if (CacheUtils.allowCacheData())
                localStorage.setItem(CacheKeys.HiddenSetting, JSON.stringify(retObj));
        }
    }

    return retObj;
}

async function getEmailJobSetting() {
    const clientId = clientService.getClientId();
    const url = UrlHelper.resolveApiUrl(`client/settings?id=${clientId}&prefix=${SettingKeys.EMAIL_JOB}`);
    const result = await axios.get(url);

    const retObj = {
        JobEnabled: false,
        MaxEmailPerBatch: 100
    };

    if (result.data.status == 0 && result.data.data.length > 0) {
        const item = result.data.data[0];

        if (item.value)
            Object.assign(retObj, JSON.parse(item.value));
    }

    return retObj;
}

async function getGanttChartColorSetting() {
    const clientId = clientService.getClientId();
    const url = UrlHelper.resolveApiUrl(`client/settings?id=${clientId}&prefix=${SettingKeys.GANTT_CHART_COLOR}`);
    const result = await axios.get(url);

    const retObj = {
        TaskBgColor: '#0042e9',
        TaskColor: '#FFFFFF',
        ProgressBgColor: '#0ab39c'
    };

    if (result.data.status == 0 && result.data.data.length > 0) {
        const item = result.data.data[0];

        if (item.value)
            Object.assign(retObj, JSON.parse(item.value));
    }

    return retObj;
}

async function getSignicatSetting() {
    const clientId = clientService.getClientId();
    const url = UrlHelper.resolveApiUrl(`client/settings?id=${clientId}&prefix=${SettingKeys.SIGNICAT}`);
    const result = await axios.get(url);

    const retObj = {
        ContactName: "Cateno Service First",
        ContactPhone: "+4770238400",
        ContactEmail: "tuannh@cateno.com",
        ContactSiteUrl: "https://cateno.servicefirst.no",

        BaseUrl: "https://api.signicat.io",
        ClientId: "t3cacd587d51543cdb51a451d9bc17d76",
        ClientSecret: "mn7iCiSrhzP6mEPXFAlC6B1FbhZA24VU",
        SignatureMethods: [
            "no_bankid_netcentric",
            "no_bankid_mobile",
            "no_buypass"
        ],
        SignatureType: "identification",
        AuthMechanism: "off",

        JobEnabled: false
    };

    if (result.data.status == 0 && result.data.data.length > 0) {
        const item = result.data.data[0];

        if (item.value)
            Object.assign(retObj, JSON.parse(item.value));
    }

    return retObj;
}

async function getEmailTicketSetting() {
    const clientId = clientService.getClientId();
    const url = UrlHelper.resolveApiUrl(`client/settings?id=${clientId}&prefix=${SettingKeys.EMAIL_TICKET_SETTINGS}`);
    const result = await axios.get(url);

    const retObj = {
        JobEnabled: false,

        AllowAnswerCustomerTicket: false,
        DefaultSenderEmail: 'ticket@sf.no',
        ReplyTo: 'info@sf.no',

        DefaultTicketStatusId: 0, // Do not user this setting. Change to use DefaultTicketStatusIdET in Hidden setting.
        DefaultTicketOwnerId: 0,
        SkipWords: '',

        Pop3Server: '',
        Pop3Port: 80,
        EnableSSL: false,
        Email: '',
        Password: '',
        DefaultAssigneeId: 0,

        FetchAccounts: [], // Every fetch account need email, password and DefaultAssigneeId,

        ImapPort: 143,
        ImapEnableSSL: false,
        ImportSpamFolder: false,
        SpamFolder: "Junk",
        DeleteDays: 60, // •	Auto delete spam emails after __X___ days với X là tự nhập bởi admin và là giá trị integer > 0. Khi đó email với ngày import > X days sẽ bị tự delete khỏi SPAM
    }

    if (result.data.status == 0 && result.data.data.length > 0) {
        const item = result.data.data[0];

        if (item.value)
            Object.assign(retObj, JSON.parse(item.value));
    }

    return retObj;
}

async function testPop3(hostname, port, useSsl, username, password, imapPort, imapEnableSSL) {
    let url = `ticket/testPop3?hostname=${encodeURIComponent(hostname)}`
    url = `${url}&port=${port}&imapPort=${imapPort}&useSsl=${useSsl}&imapEnableSSL=${imapEnableSSL}&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`

    try {
        url = UrlHelper.resolveApiUrl(url);
        const result = await axios.post(url);
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}

async function getCustomerImportSetting() {
    const clientId = clientService.getClientId()
    const url = UrlHelper.resolveApiUrl(`client/settings?id=${clientId}&prefix=${SettingKeys.CSHOP_CUSTOMER_IMPORT_SETTINGS}`);
    const result = await axios.get(url)

    const retObj = {
        JobEnabled: false,
        CshopSiteUrl: '',
        ApiKey: '',
        DefaultUserId: 0,
        DefaultCategoryId: 0,
        PageSize: 100,
        DefaultCompanyId: clientId
    };

    if (result.data.status == 0 && result.data.data.length > 0) {
        const item = result.data.data[0];

        if (item.value)
            Object.assign(retObj, JSON.parse(item.value));
    }

    return retObj;
}

async function getUserSettings() {
    const clientId = clientService.getClientId();
    const url = UrlHelper.resolveApiUrl(`client/settings?id=${clientId}&prefix=${SettingKeys.USER_SETTINGS}`);
    const result = await axios.get(url);

    const retObj = {
        Theme: 'dark', // support: dark, light
        Language: process.env.VUE_APP_I18N_LOCALE // support: en, no
    };

    if (result.data.status == 0 && result.data.data.length > 0) {
        const item = result.data.data[0];

        if (item.value)
            Object.assign(retObj, JSON.parse(item.value));
    }

    return retObj;
}

async function saveUserSettings(language, theme) {
    const obj = {
        Theme: theme,
        Language: language
    };

    const item = { id: 0, name: SettingKeys.USER_SETTINGS, value: '', updatedDate: new Date };
    item.value = JSON.stringify(obj);
    item.updatedDate = new Date;

    try {
        const ret = await save([item]);
        return ret;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}

function clearCache() {
    if (localStorage.getItem(CacheKeys.SiteSettings))
        localStorage.removeItem(CacheKeys.SiteSettings)

    if (localStorage.getItem(CacheKeys.HiddenSetting))
        localStorage.removeItem(CacheKeys.HiddenSetting)
}