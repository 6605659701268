import axios from 'axios';
import { UrlHelper } from "@/helpers/url.helper";
import { CacheKeys } from '../enums/cache-keys';
import { CryptUtils } from "../helpers/crypt.utils";
import { CacheUtils } from "../helpers/cache.utils";
import i18n from '@/i18n';
import { userService } from "./user.service";

export const clientService = {
    getClientList,
    getClientInfo,
    getSmsBalance,
    orderSmsPackage,
    getClientId,
    clearCache,
    getSiteName,

    reportSummary
};

async function getClientList() {

    try {
        const clientList = localStorage.getItem(CacheKeys.ClientList)
        if (CacheUtils.allowCacheData() && clientList)
            return JSON.parse(CryptUtils.Decrypt(clientList))

        let url = UrlHelper.resolveApiUrl('user/getUserInfo?clientlist=1');
        let ret = await axios.get(url);

        if (CacheUtils.allowCacheData() && ret.data.status == 0 && ret.data.data?.clients.length > 0)
            localStorage.setItem(CacheKeys.ClientList, CryptUtils.Encrypt(JSON.stringify(ret.data.data?.clients)))

        return ret.data.data?.clients
    }
    catch (err) {
        console.log('getClientList', err)

        return []
    }
}

function getClientId() {
    return parseInt(localStorage.getItem('clientId') || '0')
}

function clearCache() {
    if (localStorage.getItem(CacheKeys.ClientList))
        localStorage.removeItem(CacheKeys.ClientList)

    if (localStorage.getItem(CacheKeys.ClientInfo))
        localStorage.removeItem(CacheKeys.ClientInfo)
}

function getSiteName() {
    let clientName = localStorage.getItem('clientName');
    clientName = clientName || i18n.global.t('g-site-name');

    return ` | .: ${clientName} :.`
}

async function getClientInfo() {

    const clientInfo = localStorage.getItem(CacheKeys.ClientInfo)
    if (CacheUtils.allowCacheData() && clientInfo)
        return JSON.parse(CryptUtils.Decrypt(clientInfo))

    try {
        const domain = location.hostname == 'localhost' ? process.env.VUE_APP_DEFAULT_DEV_DOMAIN : location.hostname
        const url = UrlHelper.resolveApiUrl(`client/get?domain=${domain}`);
        const ret = await axios.get(url);
        const client = ret.data.data

        if (CacheUtils.allowCacheData() && ret.data.status == 0 && client)
            localStorage.setItem(CacheKeys.ClientInfo, CryptUtils.Encrypt(JSON.stringify(client)))

        localStorage.setItem('clientId', client?.id)
        localStorage.setItem('clientName', client?.name)

        return client
    }
    catch (err) {
        console.log(err)
    }

    return null
}

async function getSmsBalance() {

    try {
        const domain = location.hostname == 'localhost' ? process.env.VUE_APP_DEFAULT_DEV_DOMAIN : location.hostname
        const url = UrlHelper.resolveApiUrl(`client/get?domain=${domain}`);
        const ret = await axios.get(url);
        ret.data.data.smsBalance;

        return ret.data.status == 0 ? ret.data.data.smsBalance : 0;
    }
    catch (err) {
        console.log(err)
    }

    return 0;
}

async function orderSmsPackage(packageId) {

    try {
        const user = userService.getUserinfo();
        const firstName = user.firstName;
        const lastName = user.lastName;
        const email = user.email;
        const clientId = getClientId();

        const url = UrlHelper.resolveApiUrl(`client/sendSmsPackageEmail?clientId=${clientId}&packageId=${packageId}&firstName=${firstName}&lastName=${lastName}&email=${email}`);
        const ret = await axios.post(url);

        return ret.data;
    }
    catch (err) {
        return { status: 1, message: err };
    }
}

async function reportSummary() {

    try {
        const clientId = getClientId();
        const url = UrlHelper.resolveApiUrl(`client/reportSummary?id=${clientId}`);
        const ret = await axios.post(url);

        return ret.data;
    }
    catch (err) {
        return { error: 1, message: err };
    }
}