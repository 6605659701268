import { MenuPerms } from '../enums/menu-perms';
import { PermissionUtilCustomer } from './permission.util/permission.util.customer';
import { PermissionUtilProject } from './permission.util/permission.util.project';
import { PermissionUtilProjectTicket } from './permission.util/permission.util.project-ticket';
import { PermissionUtilEmailTicket } from './permission.util/permission.util.email-ticket';
import { PermissionUtilDigitalSigning } from './permission.util/permission.util.digital-signing';

export const PermissionUtil = {
    initMenuPermission,
    allSupportMenu,
    initPermission,
    getPermission,

    Customer: {
        allowAccess: PermissionUtilCustomer.allowAccess,
        allowView: PermissionUtilCustomer.allowView,
        allowEdit: PermissionUtilCustomer.allowEdit
    },

    Project: {
        allowEdit: PermissionUtilProject.allowEdit,
        allowViewAll: PermissionUtilProject.allowViewAll,
        allowViewOwned: PermissionUtilProject.allowViewOwned
    },

    Ticket: {
        allowEdit: PermissionUtilProjectTicket.allowEdit,
        allowViewAll: PermissionUtilProjectTicket.allowViewAll,
        allowViewOwned: PermissionUtilProjectTicket.allowViewOwned
    },

    EmailTicket: {
        allowViewAll: PermissionUtilEmailTicket.allowViewAll,
        allowViewAssigned: PermissionUtilEmailTicket.allowViewAssigned,
        allowEdit: PermissionUtilEmailTicket.allowEdit,
        allowViewUnassigned: PermissionUtilEmailTicket.allowViewUnassigned
    },

    DigitalSigning: {
        viewOnly: PermissionUtilDigitalSigning.viewOnly,
        notAllowed: PermissionUtilDigitalSigning.notAllowed
    }
}

function initMenuPermission(menuName) {
    if (menuName == MenuPerms.CustomerCardAndContactPersons)
        return {
            canAdministrate: false, // Include add/edit           
            viewOnly: false,
            notAllowed: true
        }

    if (menuName == MenuPerms.RelationshipManagementWithContactPersons)
        return {
            canAdministrate: false, // Include add/edit      
            viewOnly: false,
            notAllowed: true
        }

    if (menuName == MenuPerms.LoggingOfCommunications)
        return {
            canAdministrate: false, // Include add/edit      
            viewOnly: false,
            notAllowed: true
        }

    if (menuName == MenuPerms.Calendar)
        return {
            manageEvent: false,
            viewOnly: false
        }

    if (menuName == MenuPerms.GDPR)
        return {
            viewOnly: false,
            notAllowed: true
        }

    if (menuName == MenuPerms.EmployeeAbsenceManagement)
        return {
            viewOnly: false,
            notAllowed: true
        }

    if (menuName == MenuPerms.SalesLeadManagement)
        return {
            viewOnly: false,
            notAllowed: true
        }

    if (menuName == MenuPerms.APIAccess)
        return {
            viewOnly: false,
            notAllowed: true
        }

    if (menuName == MenuPerms.DigitalSignatureOption)
        return {
            viewOnly: false,
            notAllowed: true
        }

    if (menuName == MenuPerms.ProjectManagementWithTicketFunction)
        return {
            canSeeAllProjects: false,
            canSeeOnlyProjectsOwned: false,
            canAdministrate: false, // Include add/edit      
        }

    if (menuName == MenuPerms.EmailTicketHandling)
        return {
            canSeeAllEtickets: false,
            canSeeOnlyEticketsAssignedAndIsViewer: false,
            canEditEtickets: false,
            canSeeUnassignedEtickets: false
        }
}

function allSupportMenu() {

    // Platinum package
    return [
        MenuPerms.CustomerCardAndContactPersons,
        MenuPerms.RelationshipManagementWithContactPersons,
        MenuPerms.LoggingOfCommunications,
        MenuPerms.Calendar,
        MenuPerms.GDPR,
        MenuPerms.EmployeeAbsenceManagement,
        MenuPerms.SalesLeadManagement,
        MenuPerms.APIAccess,
        MenuPerms.DigitalSignatureOption,
        MenuPerms.ProjectManagementWithTicketFunction,
        MenuPerms.EmailTicketHandling
    ]
}

function initPermission() {
    const arrMenu = allSupportMenu()
    let obj = {}

    for (let menu of arrMenu) {
        obj[menu] = initMenuPermission(menu)
    }

    return obj
}

function getPermission(roles) {
    let perm = initPermission()

    if (roles && roles.length > 0) {
        const arrMenu = allSupportMenu()

        // merged permission from all roles
        for (let role of roles) {

            if (role.isActive && role.isSelected && role.rolePermission && role.rolePermission != '') {
                var rolePerm = JSON.parse(role.rolePermission)

                for (let menu of arrMenu) {
                    const arrKeys = Object.keys(rolePerm[menu])
                    for (let key of arrKeys) {

                        if (key == 'notAllowed' && !rolePerm[menu][key])
                            perm[menu][key] = rolePerm[menu][key]

                        else if (rolePerm[menu][key])
                            perm[menu][key] = rolePerm[menu][key]
                    }
                }
            }
        }
    }

    return perm
}
