import axios from 'axios';
import { userService } from "../services/user.service"
// import { useRouter } from 'vue-router'

export function JwtInterceptor() {

    axios.interceptors.request.use(request => {
        // add auth header with jwt if account is logged in and request is to the api url

        const jwt = userService.getToken()

        if (jwt)
            request.headers["Authorization"] = `Bearer ${jwt}`;

        if (process.env.VUE_APP_DEFAULT_DEV_DOMAIN)
            request.headers["sf-client"] = process.env.VUE_APP_DEFAULT_DEV_DOMAIN;
        else
            request.headers["sf-client"] = location.hostname;

        return request;
    });

    axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, function (error) {

        if (error?.response?.status == 401) {
            // userService.logout()
        }

        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    });


}