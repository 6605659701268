import { MenuPerms } from '../../enums/menu-perms'
import { PermKeys } from '../../enums/perm-keys'

export const PermissionUtilCustomer = {
    allowAccess,
    allowView,
    allowEdit,
}

function allowAccess() {
    const perms = window.UserPerms

    if (perms && perms[MenuPerms.CustomerCardAndContactPersons]) {
        const checkPerm = perms[MenuPerms.CustomerCardAndContactPersons]
        const ret = checkPerm[PermKeys.notAllowed] == false || allowEdit()

        return ret
    }

    return false
}

function allowView() {
    const perms = window.UserPerms
    if (perms && perms[MenuPerms.CustomerCardAndContactPersons]) {
        const checkPerm = perms[MenuPerms.CustomerCardAndContactPersons]
        const ret = allowEdit() || checkPerm[PermKeys.viewOnly] == true || checkPerm[PermKeys.notAllowed] == false

        return ret
    }

    return false
}

function allowEdit() {
    const perms = window.UserPerms

    if (perms && perms[MenuPerms.CustomerCardAndContactPersons]) {
        const checkPerm = perms[MenuPerms.CustomerCardAndContactPersons]
        const ret = checkPerm[PermKeys.canAdministrate] == true

        return ret
    }

    return false
}