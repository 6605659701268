export const state = {
  count: 0,
  countMessage: 0,
  countAlert: 0,

  all: [],
  messages: [],
  alerts: [],

  reload: false
};

export const mutations = {
  resetData(state) {
    state.count = 0;
    state.countMessage = 0;
    state.countAlert = 0;

    state.all = [];
    state.messages = [];
    state.alerts = [];

    state.reload = false;
  },
  addCount(state, value) {
    state.count += value
  },
  addCountMessage(state, value) {
    state.countMessage += value
  },
  addCountAlert(state, value) {
    state.countAlert += value
  },
  setReload(state, value) {
    state.reload = value
  },
  addAll(state, item) {
    state.all.push(item)
  },
  addMessage(state, item) {
    state.messages.push(item)
  },
  addAlert(state, item) {
    state.alerts.push(item)
  }
};

export const actions = {
  resetData({ commit }) {
    commit('resetData');
  },
  addCount({ commit }, value) {
    commit('addCount', value);
  },
  addCountMessage({ commit }, value) {
    commit('addCountMessage', value);
  },
  addCountAlert({ commit }, value) {
    commit('addCountAlert', value);
  },
  setReload({ commit }, value) {
    commit('setReload', value);
  },
  addAll({ commit }, item) {
    commit('addAll', item);
  },
  addMessage({ commit }, item) {
    commit('addMessage', item);
  },
  addAlert({ commit }, item) {
    commit('addAlert', item);
  }
};
