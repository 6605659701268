

export const SettingKeys = {
    SMTP: 'smtp',
    GENERAL: 'general',
    SF_CENTER_GENERAL: 'general',
    SMS: 'sms',
    POGO: 'pogo',
    TRIPLETEX: 'tripletex',
    EMAIL_JOB: 'email_job',
    EMAIL_TICKET_SETTINGS: 'email_ticket_settings', // include email ticket import job setting 
    CSHOP_CUSTOMER_IMPORT_SETTINGS: 'cshop_customer_import', // include cshop customer import job setting
    USER_SETTINGS: 'user_settings',

    TEMPLATE_SEND_INVITE: 'template_send_invite',
    TEMPLATE_SEND_RESET_PASSWORD: 'template_send_reset_password',

    TEMPLATE_CREATE_TICKET: 'template_create_ticket',
    TEMPLATE_ASSIGN_TICKET: 'template_assign_ticket',
    TEMPLATE_COMMENT_TICKET: 'template_comment_ticket',
    TEMPLATE_SEND_ET_TO_CUSTOMER: 'template_send_et_to_customer',
    TEMPLATE_ASSIGN_PROJECT_TASK: 'template_assign_project_task',

    HIDDEN: 'hidden_settings',

    GANTT_CHART_COLOR: 'gantt_chart_color',

    SIGNICAT: 'signicat'
}